<template>
  <div class="mine page">
    <div class="page-bg" ></div>
    <div class="wrapper">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" success-text="Success">
        <template #pulling>
          Cuộn xuống để Refresh
        </template>
        <template #loosing>
          Cuộn xuống để Refresh
        </template>
        <template #loading>
          Đang tải
        </template>
         <div class="header">
           <van-nav-bar class="nav-bar">
             <template #right>
               <van-icon name="setting-o" @click="showSetting()" color="#fff"/>
             </template>
           </van-nav-bar>
           <div class="user-wrapper" @click="doLogin()">
             <van-image round class="user_img" :src="require('../../assets/999.png')">
               <template v-slot:loading>
                 <van-loading type="spinner"/>
               </template>
             </van-image>
             <div class="login-content">
               <p class="login-btn" >{{this.userInfo.username}} <van-image class="mine-img" :src="'img/mine/vip' + userInfo.vip + '.png'">
                            <template v-slot:loading>
                              <van-loading type="spinner"/>
                            </template>
                          </van-image>  
                         </p>
               <p class="login-label"><div  class="van-progress" style="height: 8px;"><span class="van-progress__portion" style="background: linear-gradient(270deg, rgb(194, 68, 145), rgb(119, 95, 217)); width: 28.73px;"></span></div></p>
             </div>
           </div>
         </div>
         <div class="content">
            <div class="finance">
            <div class="finance-item"  @click="doPay()">
              <van-icon class="icon" style="" name="peer-pay" />
              <span class="text">Nạp tiền</span>
            </div>
            <div class="line"></div>
            <div class="finance-item"  @click="doWithdrawal()">
              <van-icon class="icon" name="idcard" />
              <span class="text">Rút tiền</span>
            </div>
          </div>
            <div v-if="this.userInfo.money" class="wallet">
                <div class="part-1 van-hairline--bottom">
                  <p class="flex-1 font-28 font-primary-color" style="color: #c24491">Ví của tôi</p>
                  <span class="font-28 font-gray">Chi tiết</span>
                  <van-icon class="font-gray" style="font-size: 28px" name="arrow" />
                </div>
                <div class="part-2">
                  <p class="balance van-ellipsis" style="color: #c24491">{{Math.trunc(this.userInfo.money)}}</p>
                  <span class="font-28 font-gray">Số dư (₫)</span>
                  <div class="refresh-btn" @click="refresh()"><van-icon name="replay" /></div>
                </div>
                <div class="part-2">
                  <p class="balance van-ellipsis" style="color: #c24491">{{Math.trunc(this.userInfo.amount_code)}}</p>
                  <span class="font-28 font-gray">Số dư khả dụng (₫)</span>
                  <div class="refresh-btn" @click="refresh()"><van-icon name="replay" /></div>
                </div>
            </div>
            <div :style="{ marginTop: menu_top +'px'}" class="menu" >
              <div class="menu-item" @click="$router.push({path:'/Personalreport'});">
                <van-image class="menu-item-icon" src="img/mine/baobiao.svg">
                  <template v-slot:loading>
                    <van-loading type="spinner"/>
                  </template>
                </van-image>
                <span class="menu-item-label" >Đổi thành tiền</span>
              </div>
             
              <div class="menu-item" @click="toNotice()">
                <van-image class="menu-item-icon" src="img/mine/gonggao.svg">
                  <template v-slot:loading>
                    <van-loading type="spinner"/>
                  </template>
                </van-image>
                <span class="menu-item-label" >Thông báo</span>
              </div>
              
            </div>
         </div>
      </van-pull-refresh>
    </div>
    <van-popup v-model="show" round @closed="form.amount = ''" :close-on-click-overlay="!payLoading">
      <div class="pay-content">
        <div>
          <van-field readonly v-model="form.bank_number" style="margin-bottom: 8px;width: 75%; float: left;" label="Số thẻ" placeholder="Vui lòng nhập số thẻ" /><span class="fz" @click="copyText(form.bank_number)">Sao chép</span>
          <van-field readonly v-model="form.bank_name" style="margin-bottom: 8px;width: 75%; float: left;" label="Ngân hàng" placeholder="Vui lòng nhập tên của bạn" /><span class="fz" @click="copyText(form.bank_name)">Sao chép</span>
          <van-field readonly v-model="form.cname" style="margin-bottom: 8px;width: 75%; float: left;" label="Tên" placeholder="Tên người nhận" /><span class="fz" @click="copyText(form.cname)">Sao chép</span>
          <van-field v-model="form.amount" :readonly="payLoading" label="money" style="margin-bottom: 8px" placeholder="Vui lòng nhập số tiền nạp" />
          <van-button round block type="info" :disabled="!form.amount" @click="pay" :loading="payLoading" loading-text="charging">Xác nhận</van-button>
        </div>
      </div>
    </van-popup>
  </div>

</template>

<script>
export default {
  data() {
    return {
      userInfo:{
      },
      show: false,
      payLoading: false,
      form: {
        bank_number: localStorage.getItem('bank_number'),
        bank_name: localStorage.getItem('bank_name'),
        cname: localStorage.getItem('cname'),
        amount: ''
      },
      menu_top:40,
      isLoading: false,
    };
  },
  methods: {
    pay() {
      if(!this.form.amount) return this.$toast("Vui lòng điền số tiền");
      this.payLoading = true
      this.$http({
        method: 'post',
        data: this.form,
        url: 'recharge_apply'
      }).then(res=>{
          if(res.code === 200) {
            this.show = false
            this.$toast("Gửi đánh giá");
          } else {
            this.show = false
            this.$toast("Vui lòng liên hệ dịch vụ khách hàng");
          }
      })
      .catch(() => {
        this.show = false
        this.$toast("Vui lòng liên hệ dịch vụ khách hàng");
      })
    },
    refresh(){
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if(localStorage.getItem('token')){
          this.$toast("loading");
        }else{
          this.$router.push({path:'/Login'})
        }
      }, 500);
    },
    exit(){
      this.$toast("Vui lòng điền vào danh sách nhiệm vụ và nhập");
    },
    showSetting() {
      if(localStorage.getItem('token')){
        this.$router.push({path:'/Setting'})
      }else{
        this.$router.push({path:'/Login'})
      }
    },
    toNotice(){
      if(localStorage.getItem('token')){
        this.$router.push({path:'/Notice'})
      }else{
        this.$router.push({path:'/Login'})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if(localStorage.getItem('token')){
            this.getUserInfo();
            this.$toast("Success");
        }else{
            this.$router.push({path:'/Login'})
        }
      }, 500);
    },
    copyText(text){
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.$toast("Sao chép thành công");
    },
    doLogin(){
        if(localStorage.getItem('token')){
            this.$router.push({path:'/Infomation'});
        }else {
            this.$router.push({path:'/Login'})
        }
    },
    doPay(){
      if(localStorage.getItem('token')){
        this.show = true
      }else {
        this.$router.push({path:'/Login'})
      }
      // this.$toast("Vui lòng liên hệ bộ phận chăm sóc khách hàng để nạp tiền");
      // return false;
    },
    doWithdrawal(){

      this.$router.push("withdraw");
    },
    toService(){
      if(this.$store.getters.getBaseInfo.iskefu == 1){
        this.$router.push("ServiceOnline");
      }else {
        this.$toast.fail("Vui lòng liên hệ dịch vụ khách hàng!");
      }
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
            this.userInfo = res.data;
            console.log('userInfo===🚀===>', this.userInfo)
            this.menu_top = 15;
            if(this.userInfo.status !== 1){
              this.$toast("Offline account");
              localStorage.clear()
              this.$router.push({path:'/Login'})
            }
            if(this.userInfo.is_online !== 1){
              this.$toast("Offline account");
              localStorage.clear()
              this.$router.push({path:'/Login'})
            }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
  },
  created() {
    if(localStorage.getItem('token')){
        this.getUserInfo();
    }else {
      this.userInfo.username = "Login/Registration";
      this.userInfo.ip = "Đăng nhập để tận hưởng nhiều dịch vụ hơn!";
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  }
};
</script>

<style lang='less' scoped>

.mine-img{
width: 20vw;
    height: 7vw;
  }  

  .vip{
 //   font-size: 18px;
  }  

.pay-content {
  width: 80vw;
  height: 530px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  ::v-deep .van-cell {
    padding: 4.333vw 2.133vw;
  }
  ::v-deep .van-button--normal {
    padding: 4vw 2vw;
  }
}
.page{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.mine{
  position: relative;
  bottom: 10px;
  background: #f2f2f5;
}
.mine .wrapper{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.nav-bar{
  background: linear-gradient(90deg,#775fd9,#c24491);
}
.mine .header{
  background: linear-gradient(90deg,#775fd9,#c24491);
  
  padding-bottom: 100px;

}
::v-deep .van-nav-bar__content {
  height: 100px;
}
::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0px;
}
.mine .header .van-nav-bar .van-icon {
  font-size: 45px;
}
.mine .header .user-wrapper{
  display: flex;
  align-items: center;
  margin: 0px 40px 0px 40px;

}
.mine .user_img{
  height: 130px;
  width: 130px;
}
::v-deep .van-loading__spinner {
  height: 50px;
  width: 50px;
}
::v-deep .van-image__error-icon {
  font-size: 70px;
}
.mine  .header .user-wrapper .login-content{
  flex: 1;
  margin-left: 30px;
}
.mine  .header .user-wrapper .login-content .login-btn{
  display: inline-block;
  font-size: 40px;
  line-height: 0px;
  color: #fff;
}
.mine .header .user-wrapper .login-content .login-label{
  margin-top: -13px;
  font-size: 28px;
  color: hsla(0,0%,100%,.6);
}
.mine .page-bg{
  height: 500px;
  background: linear-gradient(90deg,#775fd9,#c24491);
}
.mine .content{
  position: relative;
  padding: 10px 30px 30px;
  min-height: 500px;
  background-color: #f2f2f5;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #ffffff;
  font-size: 35px;
}

.mine .wrapper .content .finance {
  position: absolute;
  display: flex;
  align-items: center;
  top: -55px;
  left: 30px;
  right: 30px;
  height: 120px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .finance .line{
  width: 3px;
  height: 40px;
  background-color: #ccc;
}
.mine .wrapper .content .finance .finance-item{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.mine .wrapper .content .finance .finance-item .text{
  margin-left: 30px;
  font-size: 30px;
  color: #c24491;
  font-weight: 500;
}
.mine .wrapper .content .finance .finance-item .icon{
  font-size: 50px;
  color: #c24491;
}
.mine .wrapper .content .menu{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .menu .menu-item{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 130px;
}
.mine .wrapper .content .menu .menu-item .menu-item-label{
  font-size: 30px;
  color: #c24491;
  font-weight: 500;
}
.mine .wrapper .content .menu .menu-item .menu-item-icon{
  margin: 25px;
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
}
.mine .wrapper .content .wallet{
  margin-top: 80px;
  padding: 0 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .wallet .part-1{
  display: flex;
  align-items: center;
  height: 100px;
}
.mine .wrapper .content .wallet .font-primary-color{
  color: #000;
}
.font-gray {
  color: #868686;
}
.mine .wrapper .content .wallet .part-2{
  display: flex;
  align-items: center;
  height: 150px;
}
.mine .wrapper .content .wallet .part-2 .balance{
  flex: 1;
  font-size: 60px;
  color: #7e5678;
  font-weight: 700;
}
.mine .wrapper .content .wallet .van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.mine .wrapper .content .wallet .part-2 .refresh-btn{
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 30px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #c24491;
}
.fz{
  font-size: 16px;
  color: #ffffff;
  background-color: #c24491;
  width: 25%;
  padding: 20px 0;
  text-align: center;
  border-radius: 10px;
  margin-top: 20px;
  float: right;
}
</style>
