<template>
	<div class="convention-hall page">
		<van-nav-bar title="Nạp tiền" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="box">
			<p class="xname">{{ xuanfeidata.label }}</p>
			<!-- <p class="title">{{ this.xuanfeidata.vo_title }}</p> -->
			<!-- <van-image width="98%" fit="contain" height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" /> -->
      <van-button round="true" @click="doPay()" class="button" color="linear-gradient(90deg,#775fd9,#c24491)">Hẹn Hò</van-button>
   
		
		</div>
    <div class="convention-item">
      <van-tabs animated sticky line-width="100px" :swipeable="true">
        <van-tab title="Giới thiệu">
          <van-image width="98%" fit="contain" height="100%" :key="k" :src="xuanfeidata.pic" />
          <div  class="movie-list-n-item-bottom">
  <div  class="movie-n-time-div"><img  src="img/mine/a1.png" alt="" class="movie-list-ico-loves"><img  src="img/mine/a1.png" alt="" class="movie-list-ico-loves"><img  src="img/mine/a1.png" alt="" class="movie-list-ico-loves"><img  src="img/mine/a1.png" alt="" class="movie-list-ico-loves"><img  src="img/mine/a1.png" alt="" class="movie-list-ico-loves"><img  src="img/mine/a1.png" alt="" class="movie-list-ico-loves">
    <div  class="movie-list-money"><img  src="img/mine/a2.png" alt="" class="movie-list-ico-money" style="width: 20px;">{{ xuanfeidata.money}}</div>
    <div  class="movie-list-addr"><img  src="img/mine/a3.png" alt="" class="movie-list-ico-addr"> {{ xuanfeidata.address }}</div>
    <div  class="movie-list-notice"><img  src="img/mine/a4.png" alt="" class="movie-list-ico-notice">{{ xuanfeidata.birthday }}</div>
  </div>
  <div  class="table-title">Thông số</div>
   
  <div  class="table">
  <div  class="table-list">
    <div  class="content" style="width: 100%;" v-html="xuanfeidata.content"></div>
  </div>
  <div  class="table-list">
    <div  class="name">Nghệ danh</div>
    <div  class="content">{{ xuanfeidata.xuanfei_name}}</div>
  </div>
  <div  class="table-list">
    <div  class="name">Năm sinh</div>
    <div  class="content">{{ xuanfeidata.year }}</div>
  </div>
  <div  class="table-list">
    <div  class="name">Cao (cm)</div>
    <div  class="content">{{ xuanfeidata.height }}</div>
  </div>
  <div  class="table-list">
    <div  class="name">Nặng (kg)</div>
    <div  class="content">{{ xuanfeidata.weight }}</div>
  </div>
  <div  class="table-list">
    <div  class="name">Vòng 1 (cm)</div>
    <div  class="content">{{ xuanfeidata.chest }}</div>
  </div>
  <div  class="table-list">
    <div  class="name">Vòng 2 (cm)</div>
    <div  class="content">{{ xuanfeidata.chest1 }}</div>
  </div>
  <div  class="table-list">
    <div  class="name">Vòng 3 (cm)</div>
    <div  class="content">{{ xuanfeidata.chest2 }}</div>
  </div>
  
  <div  class="table-list">
    <div  class="name">Từ chối</div>
    <div  class="content">{{ xuanfeidata.refuse }}</div>
  </div>
</div>

</div>

        </van-tab>
        <van-tab title="Hình ảnh">
          <div  class="tabs1" v-for="(v, k) in datalist" :key="k">
            <van-image class="van-image__img" :src="v">
							<template v-slot:loading>
								<van-loading type="circular" />
							</template>
						</van-image>

           
        </van-tab>
      </van-tabs>
    </div>

	</div>

  <van-popup v-model="show" round @closed="form.amount = ''" :close-on-click-overlay="!payLoading">
      <div class="pay-content">
        <div>
        
          <van-field v-model="form.amount" :readonly="payLoading" label="money" style="margin-bottom: 8px" placeholder="Vui lòng nhập số tiền nạp" />
          <van-button round block type="info" :disabled="!form.amount" @click="pay" :loading="payLoading" loading-text="charging">Xác nhận</van-button>
        </div>
      </div>
    </van-popup>

</template>




<script>
 
export default {
	data() {
 		return {
			xuanfeidata: [],
      datalist: [
				// {
				// 	xuanfei_name: '北京 健身达人',
				// 	img_url: 'https://kk.betman2.co/storage/41/62fe1bd26d01c_image_2022-08-18_190032731.png'
				// },
				// {
				// 	xuanfei_name: '北京 学生妹',
				// 	img_url: 'https://kk.betman2.co/storage/344/630869b4bd2d6_1.jpg'
				// }
			]
		};
	},
	methods: {
		back() {
			// this.$router.push({ path: 'list?id=' + this.$route.query.adsid + '&name=' + this.$route.query.name });
			this.$router.push({ path: 'Home' });
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;
        this.datalist = res.data.img_url;
			});
		},
		yuyue() {
			this.$toast('Vui lòng liên hệ với nhân viên tư vấn hoặc tiếp tân của chúng tôi');
		},
    doPay(){
      this.$router.push({path:'/submit?id='+this.$route.query.id});
    },
	},
 
	created() {
    this.getxuanfeidata();
    
	}
};
</script>

<style>

.container {
	display: inline-block;
}
.box {
	width: 100%;
	margin: 0 auto;
  height: 100%;
	text-align: center;

  height: 100%;
  background: #fff;

}
.xname {
	font-size: 1.125rem;
  color: #c24491;
  transform: translateY(20px);
}
.title {
	font-size: 0.625rem;
}
.button {
	width: 10rem;
	height: 2.5rem;
	font-size: 0.9375rem;
	margin-top: 0.625rem;
}
::v-deep .van-tab__text {
  color: #c24491;
}
::v-deep .van-tabs__line {
  background-color: #c24491 !important
}
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.nav-bar {
  background: linear-gradient(90deg,#775fd9,#c24491);
  height: 100px;
}
.van-nav-bar {
  line-height: 50px;
}
::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
.van-sidebar {
  width: 180px;
}
.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #f2f2f5;
}
.addname {
  text-align: center;
  transform: translateY(30px);
}
::v-deep .van-tab {
  font-size: 30px;
  line-height: 100px;
  font-weight: bold;
}
::v-deep .van-tabs__line {
  background-color: #7e5678;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 10vw;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
.card {
  background-color: #775fd9;
  padding: 0.625rem;
  width: 95%;
  color: white;
  font-size: 1rem;
  margin: 0.625rem auto;
  line-height: 1.5rem;
  border-radius: 0.375rem;
}
::v-deep .van-row--flex {
 
  width: 100%;
  line-height: 80px;
}
::v-deep .van-col {
  
  width: 100%;
}
.rig-box {
  width: 95%;
  margin: 0.625rem auto;
}
.rig-title {
  color: #c24491;
  font-size: 1.425rem;
}
.rig-content {
  font-size: 30px;
  color: #c24491;
  
}
::v-deep .van-col {
  color: #c24491;
}
.address {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 110px;
  box-sizing: border-box;

}

.van-tab {
    font-size: 4vw;
    line-height: 25vw;
    font-weight: 700;
}
.van-tabs__line {
    background-color: #c24491 !important;
}
.movie-list-n-item-bottom{
    padding: 4vw;
}
.movie-list-ico-loves{
    display: inline-block;
    width: 5.333vw;
}

.movie-list-money{
    display: flex;
    align-items: center;
    margin-top: 1.333vw;
}

.movie-list-ico-money{
    width:  5.333vw;
    display: inline-block;
    margin-right: 1.333vw;
}
.movie-list-addr{
    display: flex;
    align-items: center;
    margin-top: 1.333vw;
}
.movie-list-ico-addr{
  width:  5.333vw;
    display: inline-block;
    margin-right: 1.333vw;
}
.movie-list-notice{
    display: flex;
    align-items: center;
    margin-top: 1.333vw;
}
.movie-list-ico-notice{
  width:  5.333vw;
    display: inline-block;
    margin-right: 1.333vw;
}
.van-tabs--line .van-tabs__wrap {
    height:10vw;
}
.table-title{
    padding: 4vw;
    font-size: 4.8vw;
    background: #ccc;
    border-bottom: 1px solid #eee;
}
.table .table-list {
    padding: 2.667vw 4vw;
    display: flex;
    background: #eee;
}
.table .table-list:nth-child(2n) {
    background: #999;
}
.table .table-list .name{
    width: 30%;
    display: flex;
    align-items: center;
}
.table .table-list .content{
    width: 70%;
}
.tabs1{
    display: flex;
    flex-wrap: wrap;
}
.tabs1-img{
    width: 50%;
    padding: 1.333vw;
}
</style>
