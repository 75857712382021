<template>
  <div class="container page">
    <van-nav-bar title="Rút tiền" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
      <template #right>
        <span class="nav-right" @click="$router.push({path:'/WithdrawRecord'})">Lịch sử</span>
      </template>
    </van-nav-bar>
    <div class="main">
      <div class="withdrawMoney">
        <span>Số tiền rút(₫)</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType"></span>
            <van-field v-model="withdraw_money" type="number" />
          </div>
          <span class="all" @click="allMoeny()">Tất cả</span>
        </div>
        <span>Họ và tên</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType"></span>
            <van-field v-model="tname" />
          </div>
        </div>
        <span>Tài khoản ngân hàng</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType"></span>
            <van-field v-model="bankname" />
          </div>
        </div>
        <span>Tên ngân hàng</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType"></span>
            <van-field v-model="bankcard" />
          </div>
        </div>
        <span>Vui lòng nhập mật khẩu rút tiền</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType"></span>
            <van-field v-model="paypassword" type="number" />
          </div>
        </div>
        <div class="information">
          <div class="description">
            <van-popover v-model="showPopover" trigger="click">
              <div class="popover-body" style="padding: 10px;">
                <p>1. Giới hạn giao dịch đơn: thấp nhất {{this.withdrawRole.min}} là tồi tệ nhất và cao nhất. {{this.withdrawRole.max}} Người xấu</p>
                <p>2. Số lần rút tiền: Số lần rút tiền tối đa trong ngày {{this.withdrawRole.num}} Hạng 2</p>
                <p>3. Thời gian đến: Thời gian đến trung bình khoảng 5 phút, thời gian đến nhanh nhất là 2 phút</p></div>
              <!-- <template #reference @click="withdrawInfo()">
                <van-icon name="info-o" />
                mô tả hạn ngạch
              </template> -->
            </van-popover>
          </div>

          <div class="balance">
            <span>Số dư:</span>
            <span class="number">{{Math.trunc(this.userInfo.amount_code)}}</span>
          </div>
        </div>
      </div>
      <van-button class="withdraw_btn" type="default" @click="doWithdraw()">Rút tiền ngay</van-button>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopover: false,
      withdraw_money:"",
      tname:"",
      bankname:"",
      bankcard:"",
      paypassword:"",
      userInfo:{},
      withdrawRole:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
           
          


        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserWithdrawRole(){
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_role'
      }).then(res=>{
        if(res.code === 200){
          this.withdrawRole = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    allMoeny(){
      this.withdraw_money = this.userInfo.amount_code;
    },
    doWithdraw(){
      if(this.withdraw_money <= 0){
        this.$toast("Vui lòng điền đúng số tiền.");
        return false;
      }else if(this.tname ===""){
        this.$toast("Vui lòng nhập tên.");
        return false;
        }else if(this.bankname ===""){
        this.$toast("Nhập tên ngân hàng");
        return false;
        }else if(this.bankcard ===""){
        this.$toast("Nhập số tài khoản");
        return false;
        }else {
        this.$http({
          method: 'post',
          data:{money:this.withdraw_money,name:this.tname,bankname:this.bankname,bankcard:this.bankcard,paypassword:this.paypassword},
          url: 'user_set_withdraw'
        }).then(res=>{
          if(res.code === 200){
            console.log('res===🚀===>', res)
            // location.reload()
            this.$toast(res.msg);
            this.getUserInfo();
            this.getUserWithdrawRole();
          }else if(res.code ===401){
            this.$toast(res.msg);
          }
        })
      }
    },
    withdrawInfo(){
      this.showPopover = true;
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserWithdrawRole();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 35px;
  line-height: 80px;
}
.container p{
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
.container .main{
  display: flex;
  flex-direction: column;
  background-color: #f2f2f5;
  height: calc(100% - 50px);
  position: relative;
}
.container .main .withdrawMoney{
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 35px;
  background-color: #fff;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f5;
}
.container .main .withdrawMoney .money .moneyNumber{
  font-size: 50px;
  display: flex;
  flex-direction: row;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money .all{
  color: #d10404;
}
.container .main .withdrawMoney .money .moneyNumber .van-cell {
  font-size: 50px;
  padding: 0!important;
}
.container .main .withdrawMoney .information{
  padding-bottom: 30px;
}
.container .main .withdrawMoney .information .description {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}
.container .main .withdrawMoney span{
  padding: 10px 0;
}
.container .main .withdrawMoney .information .balance .number{
  color: #d10404;
}
.withdraw_btn {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#e6c3a1,#7e5678);
}
</style>
