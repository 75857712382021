<template>
	<div class="demo">
        <van-image width="100%" fit="contain" height="100%" :key="k" :src="xuanfeidata.pic" />

         <div class="xdy xdy10" v-if="show">
  <h2 class="demo__heading">Vui lòng nhập mã hẹn hò</h2>
  <div class="demo__elems">
    <div class="hot-title-div">
          <van-field  v-model="search" style="width: 100%; font-size: 16px;" placeholder="Vui lòng nhập mã hẹn hò..." /> <span class="fz" @click="searchn()" style="font-size: 16px;width: 100px;
  padding:6px 0;">Gửi</span>

          
        </div>
     
    
    
  </div>
</div>
  <div class="xdy xdy2" v-if="show1">
    <h2 class="demo__heading">Bạn đã liên lạc với cô này được chưa?</h2>
    <div class="demo__elems">
      <div class="demo__elem demo__elem-1">Có</div>
      <div class="demo__elem demo__elem-2">Không</div>
    
      <span class="demo__hover demo__hover-1" @click="xyt1()"></span>
      <span class="demo__hover demo__hover-2" @click="tsxx1()" ></span>
     
      <div class="demo__highlighter">
        <div class="demo__elems">
          <div class="demo__elem">Có</div>
          <div class="demo__elem">Không</div>
          
        </div>
      </div>
     
     </div>
</div>
<div class="xdy xdy2" v-if="show2">
    <h2 class="demo__heading">Vui lòng tạm ứng tiền xe cho cô gái?</h2>
    <div class="demo__elems">
      <div class="demo__elem demo__elem-1">Có</div>
      <div class="demo__elem demo__elem-2">Không</div>
    
      <span class="demo__hover demo__hover-1" @click="xyt2()"></span>
      <span class="demo__hover demo__hover-2" @click="tsxx2()" ></span>
     
      <div class="demo__highlighter">
        <div class="demo__elems">
          <div class="demo__elem">Có</div>
          <div class="demo__elem">Không</div>
          
        </div>
      </div>
     
     </div>
</div>

<div class="xdy xdy2" v-if="show3">
    <h2 class="demo__heading">Cô gái đã đến điểm hẹn của bạn chưa?</h2>
    <div class="demo__elems">
      <div class="demo__elem demo__elem-1">Đã đến</div>
      <div class="demo__elem demo__elem-2">Chưa đến</div>
    
      <span class="demo__hover demo__hover-1" @click="xyt3()"></span>
      <span class="demo__hover demo__hover-2" @click="tsxx3()" ></span>
     
      <div class="demo__highlighter">
        <div class="demo__elems">
          <div class="demo__elem">Đã đến</div>
          <div class="demo__elem">Chưa đến</div>
          
        </div>
      </div>
     
     </div>
</div>

<div class="xdy xdy2" v-if="show4">
    <h2 class="demo__heading">Bạn cảm thấy cô gái này phục vụ bạn thế nào?</h2>
    <div class="demo__elems">
      <div class="demo__elem demo__elem-1">Rất tốt</div>
      <div class="demo__elem demo__elem-2">Không tốt</div>
    
      <span class="demo__hover demo__hover-1" @click="xyt4()"></span>
      <span class="demo__hover demo__hover-2" @click="tsxx4()" ></span>
     
      <div class="demo__highlighter">
        <div class="demo__elems">
          <div class="demo__elem">Rất tốt</div>
          <div class="demo__elem">Không tốt</div>
          
        </div>
      </div>
     
     </div>
</div>
<div class="xdy xdy2" v-if="show5" style="height: 200px;">
    <h2 class="demo__heading">Tăng lưu lượng truy cập website – Hẹn hò miễn phí với cô gái ấy!</h2>
<div class="fz" @click="tlink()" style="font-size: 16px;width: 150px; margin: 20px auto;
  padding:6px 15px 6px 15px;">Xác nhận</div>
</div>
     </div>

</template>




<script>

export default {
	data() {
		return {
			xuanfeidata: [],
            search:"",
            show: true,
            show1: false,
            show2: false,
            show3: false,
            show4: false,
            show5: false,
            link: '',
      datalist: [
				// {
				// 	xuanfei_name: '北京 健身达人',
				// 	img_url: 'https://kk.betman2.co/storage/41/62fe1bd26d01c_image_2022-08-18_190032731.png'
				// },
				// {
				// 	xuanfei_name: '北京 学生妹',
				// 	img_url: 'https://kk.betman2.co/storage/344/630869b4bd2d6_1.jpg'
				// }
			]
		};
	},
	methods: {
		back() {
			// this.$router.push({ path: 'list?id=' + this.$route.query.adsid + '&name=' + this.$route.query.name });
			this.$router.push({ path: 'Home' });
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;
        this.datalist = res.data.img_url;
        this.link = res.data.link;
			});
		},
		yuyue() {
			this.$toast('Vui lòng liên hệ với nhân viên tư vấn hoặc tiếp tân của chúng tôi');
		},
        xyt1() {
            this.show1 = !this.show1;
            this.show2 = !this.show2;
		},
        xyt2() {
            this.show2 = !this.show2;
            this.show3 = !this.show3;
		},
        xyt3() {
            this.show3 = !this.show3;
            this.show4 = !this.show4;
		},
        tsxx3() {
            this.show3 = !this.show3;
            this.show5 = !this.show5;
		},

        xyt4() {
            this.$toast('Rất tốt');
		},
        tsxx4() {
            this.$toast('Không tốt');
		},

        tsxx1() {
			this.$toast('Xin hãy liên lạc với cô ấy trước');
		},
        tsxx2() {
			this.$toast('Vui lòng tạm ứng tiền xe cho cô gái');
		},
        tlink() {
            window.location.href =localStorage.getItem('link');
		},
        searchn(){

            this.$http({
				method: 'get',
				url: 'yhm',
				data: { code:this.search}
			}).then(res => {
              
			 if(res.code === 200)
             {
                this.show = !this.show;
                this.show1 = !this.show1;
             }
             else
             {
                this.$toast('Sai mã hẹn hò~~~');   
             }
                
			});
    
    // this.$toast(this.search);
    // this.$router.push({path:'/Choose?search='+this.search})
       },
     
	},
 
	created() {
    this.getxuanfeidata();
    
	}
};
</script>

<style>
.demo {
    width: 80%;
    margin: 10%;
    background: #FFFFFF;
    box-shadow: 0 0.5rem 5rem rgba(0, 0, 0, 0.4);
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
.demo__content {
    position: relative;
}
.fz{
  font-size: 16px;
  color: #ffffff;
  background-color: #c24491;
  
  text-align: center;
  
   
  
}
body {
    background: #7B00FF;
}
.demo__heading {
    font-size: 24px;
    line-height:30px;
    padding-left: 10%;
    color: #313131;
}
.demo__content {
    position: relative;
}
.demo__elems {
    position: relative;
    height: 21rem;
}
.hot-title-div{
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.demo__elem {
    position: relative;
    height: 8vw;
    font-size: 24px;
    line-height: 8vw;
    padding-left: 3rem;
    border-top: 1px solid #e7e7e7;
}
.demo__hover-1 {
    top: 0;
}
.demo__hover-2 {
    top: 8vw;
}
.demo__hover {
    z-index: 2;
    position: absolute;
    left: 0;
    width: 100%;
    height: 8vw;
    cursor: pointer;
}
.demo__highlighter {
    z-index: 1;
    position: absolute;
    top: 0;
    left: -0.5rem;
    width: 100%;
    height: 8vw;
    background: #39A5F7;
    overflow: hidden;
    box-shadow: 0 0.3rem 0.2rem rgba(0, 0, 0, 0.3);
    transition: transform 0.5s cubic-bezier(0.59, 0.04, 0.3, 1.43);
}

.demo__highlighter .demo__elems {
    position: absolute;
    top: 0;
    left: 1rem;
    transition: transform 0.5s cubic-bezier(0.59, 0.04, 0.3, 1.43);
}
.demo__highlighter .demo__elem {
    color: #fff;
    border: none;
}
.demo__hover-1 {
  top: 0;
}
.demo__hover-1:hover ~ .demo__highlighter {
  transform: translateY(0);
}
.demo__hover-1:hover ~ .demo__highlighter .demo__elems {
  transform: translateY(0);
}
.demo__hover-2 {
  top: 8vw;
}
.demo__hover-2:hover ~ .demo__highlighter {
  transform: translateY(8vw);
}
.demo__hover-2:hover ~ .demo__highlighter .demo__elems {
  transform: translateY(-8vw);
}
.demo__hover-2:hover ~ .demo__examples .example-adv__top,
.demo__hover-2:hover ~ .demo__examples .example-adv__mid,
.demo__hover-2:hover ~ .demo__examples .example-adv__line {
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s;
}
.demo__hover-2:hover ~ .demo__examples .example-web__top,
.demo__hover-2:hover ~ .demo__examples .example-web__left {
  transform: scale(1);
  opacity: 1;
  transition: transform 0.4s 0.2s, opacity 0.4s 0.2s;
}
.demo__hover-2:hover ~ .demo__examples .example-web__right-line {
  transform: scale(1);
}
.demo__hover-2:hover ~ .demo__examples .example-web__right-line:nth-child(1) {
  transition: transform 0.4s 0s;
}
.demo__hover-2:hover ~ .demo__examples .example-web__right-line:nth-child(2) {
  transition: transform 0.4s 0.06s;
}
.demo__hover-2:hover ~ .demo__examples .example-web__right-line:nth-child(3) {
  transition: transform 0.4s 0.12s;
}
.demo__hover-2:hover ~ .demo__examples .example-web__right-line:nth-child(4) {
  transition: transform 0.4s 0.18s;
}
.demo__hover-2:hover ~ .demo__examples .example-web__right-line:nth-child(5) {
  transition: transform 0.4s 0.24s;
}
.demo__hover-2:hover ~ .demo__examples .example-web__right-line:nth-child(6) {
  transition: transform 0.4s 0.3s;
}
.demo__hover-3 {
  top: 14rem;
}
.demo__hover-3:hover ~ .demo__highlighter {
  transform: translateY(16vw);
}
.demo__hover-3:hover ~ .demo__highlighter .demo__elems {
  transform: translateY(-16vw);
}
.demo__hover-3:hover ~ .demo__examples .example-adv__top,
.demo__hover-3:hover ~ .demo__examples .example-adv__mid,
.demo__hover-3:hover ~ .demo__examples .example-adv__line {
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s;
}
.demo__hover-3:hover ~ .demo__examples .example-both {
  background: #39A5F7;
  transition: background-color 0.4s 0.2s;
}
.demo__hover-3:hover ~ .demo__examples .example-both__half {
  opacity: 1;
  transform: scale(1);
  transition: transform 0.4s 0.2s, opacity 0.4s 0.2s;
}
</style>
